const Hero = () => {

  return (
    <div id='heroComponent' className='component-background bg-bg-s-1 lg:bg-bg-1 flex justify-center items-end'>
      <div className='rounded-full flex justify-center items-center p-8 mb-12'  onClick={() => document.getElementById('musicSection').scrollIntoView({behavior: 'smooth'})}>
        <i className='heroArrow cursor-pointer fa-solid fa-angles-down text-3xl text-spanishWhite'></i>  
      </div>
    </div>
  )
}

export default Hero