import React from 'react'

const Footer = () => {

  const copyYear = new Date().getFullYear()

  return (
    <footer>
      <div className='text-center py-3'>
        <a className='social-media-icon' href='https://www.instagram.com/danieljaller/'><i className='fa-brands fa-instagram'></i><span className='sr-only'>Instagram - Cuenta principal de Daniel</span></a>
        <a className='social-media-icon' href='https://www.tiktok.com/@danieljalleroficial'><i className='fa-brands fa-tiktok'></i><span className='sr-only'>Tiktok - Cuenta principal de Daniel</span></a>
        <a className='social-media-icon' href='https://www.youtube.com/channel/UCJ1cIO4WtHsQ9c8lpkKHA1g'><i className='fa-brands fa-youtube'></i><span className='sr-only'>youTube - Cuenta principal de Daniel</span></a>
        <a className='social-media-icon' href='https://www.facebook.com/danieljalleroficial/'><i className='fa-brands fa-facebook'></i><span className='sr-only'>facebook - Cuenta principal de Daniel</span></a>
        <a className='social-media-icon' href='https://twitter.com/JallerOficial'><i className='fa-brands fa-twitter'></i><span className='sr-only'>Twitter - Cuenta principal de Daniel</span></a>
      </div>
      <div className='text-center lg:flex items-center'>
        <p>Contacto: manager@danieljaller.com</p>
        <p className='hidden lg:block'>&nbsp;&nbsp;|&nbsp;&nbsp;</p>
        <p>Daniel Jaller &copy; {copyYear}</p>
      </div>
    </footer>
  )
}

export default Footer