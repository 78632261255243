import React from 'react'
import Fade from 'react-reveal/Fade';

import djp from '../img/djp.webp'

const Music = () => {
  return (
    <div id='musicSection' className='component-background bg-bg-s-2 lg:bg-bg-2 flex justify-center items-center relative py-12'>
      <Fade bottom>
      <div className='card musicCard'>
        <figure><img className='object-top md:object-center' src={djp} alt='Album cover' /></figure>
        <div className='card-body'>
          <h2>Single: 'Último Abrazo'</h2>
          <p className='hidden lg:block'>¡Ahora disponible en todas las plataformas digitales!</p>
          <div className='flex flex-col'>
            <a href='https://open.spotify.com/album/6gYlGoH7OcUhEzcpJiUEuz' target='_blank' rel='noreferrer'><i className='fa-brands fa-spotify'></i>&nbsp;Spotify</a>
            <a href='https://www.deezer.com/us/album/299948287?app_id=140685'><i className='fa-brands fa-deezer'></i>&nbsp;Deezer</a>
            <a href='https://music.youtube.com/watch?v=_kYWmiF5lOk&feature=share'><i className='fa-solid fa-compact-disc'></i>&nbsp;YouTube Music</a>
            <a href='https://listen.tidal.com/track/219168102'><i className='fa-solid fa-grip-dots'></i>&nbsp;Tidal</a>
            <a href='https://ca.napster.com/artist/daniel-jaller/album/ultimo-abrazo'><i className='fa-brands fa-napster'></i>&nbsp;Napster</a>
            <a href='https://www.youtube.com/watch?v=PSzYEyymWkc'><i className='fa-brands fa-youtube'></i>&nbsp;YouTube</a>
          </div>
        </div>
      </div>
      </Fade>
    </div>
  )
}

export default Music