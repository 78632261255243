import React from 'react'

const About = () => {
  return (
    <div id='aboutSection' className='component-background bg-bg-s-3 lg:bg-bg-3 relative py-12 pb-32 lg:pb-12'>
      <div tabindex='0' className='aboutTab aboutTabTop'>
        <div className='collapse-title text-xl font-bold'>
          ¿Quién soy?
        </div>
        <div className='collapse-content'> 
          <p>Daniel Jaller, artista bogotano de 22 años, es considerado una de las promesas del Pop Urbano. Con un sonido fresco y cada vez más propio, suma 6 sencillos en su carrera: “La Jugada”, “Triste y Vacía”, “Amigos Na' Más”, 'Hasta el Fin del Mundo', 'Por Tu Cámara' y 'Quédate Cerca', donde hace más evidente el poder de su voz y su sello característico.</p>
          <p>Su trabajo en <strong>5ta Estudio</strong>, le ha permitido acercarse a un equipo que ha trabajado con <strong>Cali y el Dandee, Morat, Sebastián Yatra</strong>, etc. haciendo cada vez más pulido su trabajo y motivándolo a proyectarse como un artista consolidado.</p>
        </div>
      </div>
      <div tabindex='1' className='aboutTab aboutTabBottom'>
        <div className='collapse-title text-xl font-bold'>
          Mi inspiración
        </div>
        <div className='collapse-content'>
          <h3 className='font-bold mb-1'>'Los Amores de Antes'</h3>
          <p>Quiero ser la banda sonora de las historias de amor.</p>
          <p>Siento que hoy en día tenemos que rescatar el amor de antes. El romanticismo, la caballerosidad, las serenatas, las historias de amor imposibles, el tiempo en el que por amor se hacía lo que fuera. Enviar flores, escribir cartas, esos amores que lo superaban todo. Quiero volver a poner de moda sentir mariposas, arriesgarse a amar y superar la primera salida.</p>
          <p>Yo siempre he creído en el amor de verdad, amor de almas gemelas, los amores que duran para siempre. Quiero rescatar la forma en que se amaba antes, dedicar canciones, regalar estrellas, pasar toda la noche despierto hablando con ella, hacerla reír, conquistarla y enamorarla.</p>
          <p>Hoy en día estamos expuestos a una sociedad donde enamorar es cada vez más un arte del pasado. Son pocos los que creen en el amor y siempre existe la provocación de pasar el rato. Pero si nos cansamos de cantarle al amor ¿A qué le vamos a cantar? Creo firmemente que vinimos al mundo a escribir nuestra propia historia de amor.</p>
        </div>
      </div>
      <div className='absolute bottom-0 rounded-full flex justify-center items-center p-8 mb-12'  onClick={() => document.getElementById('photosSection').scrollIntoView({behavior: 'smooth'})}>
        <i className='heroArrow cursor-pointer fa-solid fa-angles-down text-3xl text-carminePink'></i>  
      </div>
    </div>
  )
}

export default About