import Fade from 'react-reveal/Fade'

const Contact = () => {
  const handleClick = (e) => {
    e.currentTarget.innerHTML = '<span>¡Gracias!</span>'
    e.currentTarget.classList.add('btn-disabled')
  }

  return (
    <div id='contactComponent' className='component-background bg-bg-s-2 lg:bg-bg-2'>
      <Fade bottom>
        <h2 className='d-block bg-arsenic py-4 px-16 rounded-xl text-xl lg:text-2xl my-12 shadow-xl opacity-95 font-bold w-11/12 lg:w-6/12 text-center'>¡Suscríbete a mi Newsletter!</h2>
      </Fade>
      <Fade bottom>
        <form action='https://send.pageclip.co/f2Jghf0V8JJa5OyMCA3sof5npxrzTjuK/Newsletter' method='post' className='pageclip-form mx-4'>
          <label htmlFor='nameInput' className='mt-6 w-11/12 lg:w-7/12'>
            <input id='nameInput' name='nameInput' type='text' placeholder='Nombre' className='input input-bordered w-full bg-spanishWhite border-carminePink border-2 w-100 ring-4 ring-transparent  active:ring-irisBlue focus:ring-irisBlue' />
          </label>
          <label htmlFor='emailInput' className='my-6 w-11/12 lg:w-7/12'>
            <input id='emailInput' name='emailInput' type='text' placeholder='Email' className='input input-bordered w-full bg-spanishWhite border-carminePink border-2 w-100 ring-4 ring-transparent  active:ring-irisBlue focus:ring-irisBlue' />
          </label>
          <label htmlFor='mobileInput' className='mb-6 w-11/12 lg:w-7/12'>
            <input id='mobileInput' name='mobileInput' type='text' placeholder='(+57) 555-123-4567' className='input input-bordered w-full bg-spanishWhite border-carminePink border-2 w-100 ring-4 ring-transparent  active:ring-irisBlue focus:ring-irisBlue' />
          </label>
          <p className='text-xs italic text-arsenic mb-6'>Al dar click en 'Enviar', autorizo a Daniel Jaller Oficial para que conserven la información aquí suministrada y me contacten con el fin de mantenerme actualizado(a) sobre el artista.</p>
          <button type='submit' className='pageclip-form__submit' onClick={(e) => handleClick(e)}>
            <span>Enviar</span>
          </button>
        </form>  
      </Fade>    
      <script src='https://s.pageclip.co/v1/pageclip.js' charset='utf-8'></script>
    </div>
  )
}

export default Contact