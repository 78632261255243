
import React from 'react'
import Fade from 'react-reveal/Fade';

import img01 from '../img/01.webp'
import img02 from '../img/02.webp'
import img03 from '../img/03.webp'
import img04 from '../img/04.webp'
import img05 from '../img/05.webp'
import img06 from '../img/06.webp'


const Photos = () => {
  return (
    <div id='photosSection' className='component-background bg-bg-s-4 lg:bg-bg-4'>
      <div className='lg:flex'>
        <Fade left>
          <img className='lg:mr-2 mb-4 lg:my-0 rounded-xl shadow-lg shadow-arsenic' src={img01} alt='Quédate Cerca - Album Cover' />
        </Fade>
        <Fade right>
          <img className='lg:ml-2 mt-4 lg:my-0 rounded-xl shadow-lg shadow-arsenic' src={img02} alt='Closeup de Daniel' />
        </Fade>
      </div>
      <div className='lg:flex my-4'>
        <Fade left>
          <img className='lg:mr-2 mb-4 lg:my-0 rounded-xl shadow-lg shadow-arsenic' src={img03} alt='Cuerpo completo de Daniel con gabardina' />
        </Fade>
        <Fade right>
          <img className='lg:ml-2 mt-4 lg:my-0 rounded-xl shadow-lg shadow-arsenic' src={img04} alt='Abriendo una portón viejo de madera' />
        </Fade>
      </div>  
      <div className='lg:flex'>
        <Fade left>
          <img className='lg:mr-2 mb-4 lg:my-0 rounded-xl shadow-lg shadow-arsenic' src={img05} alt='Sentado en una mesa de una cabaña rústica' />
        </Fade>
        <Fade right>
          <img className='lg:ml-2 mt-4 lg:my-0 rounded-xl shadow-lg shadow-arsenic' src={img06} alt='Tocando la guitarra al lado de la fogata' />
        </Fade>
      </div>    
    
    </div>
  )
}

export default Photos