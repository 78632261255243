import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Music from "./components/Music";
import Photos from "./components/Photos";

const App = () => {
  return (
    <div className="App">
      <Hero />
      <Music />
      <About />
      <Photos />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
